import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BillPageTemplate from '../components/BillPageTemplate'
import { HTMLContent } from '../components/Content'

const BillPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BillPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        asideImages={post.frontmatter.asideImages}
        notice={post.frontmatter.notice}
        rateSchedule={post.frontmatter.rateSchedule}
      />
    </Layout>
  )
}

BillPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BillPage

export const billPageQuery = graphql`
  query BillPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        notice {
          title
          message
        }
        rateSchedule {
          rate
          description
        }
        asideImages {
          image {
            childImageSharp {
              fixed (width: 500, height: 1000, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          alt
        }
      }
    }
  }
`
